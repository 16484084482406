import { addressFragment } from '~/graphql/fragments/address';

export const clientFragment = gql`
    fragment clientFragment on ClientType {
        id
        name
        contacts {
            id
            firstName
            lastName
            email
            phone
            primary
        }
        address {
            ...addressFragment
        }
        createdAt
    }
    
    ${addressFragment}
`;
